<template>
  <div class="check-box">
    <label>
      <input
        type="checkbox"
        :checked="defaultValue"
        :disabled="disabled"
        @click="!readonly ? actions.updateData($event) : ''"
      />
      <span
        v-if="label"
        class="sub-text-s2"
        :class="{ 'text-gray-third': disabled }"
        >{{ label }}</span
      >
    </label>
  </div>
</template>

<script>
export default {
  name: "InputCheckboxNew",
  props: {
    label: {
      type: String,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
    },
    defaultValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["updateData"],
  setup(props, { emit }) {
    const actions = {
      updateData: (e) => {
        emit("updateData", e.target.checked);
      },
    };

    return { actions };
  },
};
</script>

<style scoped>
.check-box {
  height: fit-content;
  align-items: center;
  display: flex;
  padding-top: 3px;
}

.check-box label {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

input[type="checkbox"] {
  border: none;
  display: block;
  margin: 0;
  width: 18px;
  height: 18px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("/assets/images/svg/ic_check_box.svg") no-repeat 0 0 / contain;
}

input[type="checkbox"]:checked {
  background: url("/assets/images/svg/ic_check_box_active.svg") no-repeat 0 0 /
    contain;
}
</style>
