<template>
  <div>
    <div class="club-item-pc">
      <div class="club-card" :class="{ temporary: state.club.isTemporary }">
        <div class="col1">
          <span class="sub-text-s2">{{ state.club.typeLabel }}</span>
        </div>

        <div class="col2">
          <p class="sub-text-s2 title" @click="actions.goToClubDetail()">
            {{ state.club.title ? state.club.title : "제목 없음" }}
          </p>

          <button class="direct-btn" @click="actions.goToClubPage()">
            <export-icon fill-color="#0D0D10"></export-icon>
          </button>
        </div>

        <div v-if="!state.club.isTemporary" class="col3">
          <span class="sub-text-s2">{{ state.salesPrice }}</span>
        </div>

        <div v-if="!club.isTemporary" class="col4">
          <button-dropdown
            distance="8"
            :auto-hide="state.statusDropdownAutoHide"
          >
            <template #button>
              <div
                ref="toggleStatusDropdownBtn"
                class="status-dropdown-btn text"
              >
                <span
                  class="sub-text-s2"
                  :class="{ 'text-blue-50': state.isOpenStatus }"
                >
                  {{ statusLabel }}
                </span>
                <i class="fa-solid fa-caret-down"></i>
              </div>
              <span
                v-if="state.isReserveStatus"
                class="c-text text-gray-second"
              >
                {{ state.startAt }}
              </span>
            </template>
            <template #dropdownList>
              <club-status-dropdown
                :club="state.club"
                @changeStatusDropdownAutoHide="
                  (value) => actions.setStatusDropdownAutoHide(value)
                "
                @closeDropdown="actions.closeStatusDropdown()"
              ></club-status-dropdown>
            </template>
          </button-dropdown>
        </div>

        <div class="col5">
          <span class="sub-text-s2">{{ state.updatedAt }}</span>
        </div>
        <div class="col6">
          <div class="function-group">
            <button class="txt-btn sub-title-s3" @click="actions.goToClubCms()">
              수정
            </button>

            <button-dropdown distance="0">
              <template #button>
                <button class="menu-dropdown-btn">
                  <more-verti-icon width="16" height="16"></more-verti-icon>
                </button>
              </template>
              <template #dropdownList>
                <ul>
                  <li v-if="false">
                    <button
                      v-if="!state.club.isTemporary"
                      v-close-popper
                      class="club-item-dropdown-btn sub-text-s2"
                      @click="actions.shareLink()"
                    >
                      <share-icon></share-icon>
                      공유
                    </button>
                  </li>
                  <li v-if="false">
                    <button
                      v-close-popper
                      class="club-item-dropdown-btn sub-text-s2"
                      @click="actions.copyClub()"
                    >
                      <copy-icon></copy-icon>
                      복제
                    </button>
                  </li>
                  <li>
                    <button
                      v-close-popper
                      class="club-item-dropdown-btn text-red-50 sub-text-s2 delete-btn"
                      @click="actions.openDeleteModal()"
                    >
                      <delete-icon fill-color="#FF4646"></delete-icon>
                      삭제
                    </button>
                  </li>
                </ul>
              </template>
            </button-dropdown>

            <button
              v-if="!state.club.isTemporary"
              class="show-ticket-btn"
              @click="actions.toggleTicketInfo()"
            >
              <arrow-icon
                width="16"
                height="16"
                :direction="state.showTicketInfo ? 'up' : 'down'"
              ></arrow-icon>
            </button>
          </div>
        </div>
      </div>

      <!--  accordion  -->
      <div ref="ticketInfo" class="ticket-info">
        <div class="ticket-info-card">
          <div class="header b-text-3 text-gray-third">
            <span class="col1">티켓명</span>
            <span class="col2">티켓별 매출</span>
            <span class="col3">티켓상태</span>
            <span class="col4">티켓 가격</span>
            <span class="col5">판매 수량</span>
            <span class="col6">남은 수량</span>
          </div>

          <ul>
            <li
              v-for="plan in state.club.allPlans"
              :key="`ticket-${plan.resourceId}`"
            >
              <club-plan-item
                :plan="plan"
                :club-resource-id="state.club.resourceId"
              ></club-plan-item>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="club-item-mo">
      <div class="header">
        <span class="text-gray-second sub-text-s2">{{ club.typeLabel }}</span>
        <div class="right-section">
          <button class="prev-btn" @click="actions.goToClubPage()">
            <export-icon class="direct-btn" fill-color="#0D0D10"></export-icon>
          </button>

          <span class="dropdown">
            <button-dropdown
              :shown="state.showMobileDropdown"
              :auto-hide="false"
              distance="8"
            >
              <template #button>
                <button
                  class="dropdown-btn"
                  @click="actions.openMobileDropdown()"
                >
                  <more-verti-icon></more-verti-icon>
                </button>
              </template>
              <template #dropdownList>
                <ul>
                  <li v-if="false && !club.isTemporary">
                    <button class="sub-text-s2" @click="actions.shareLink()">
                      <share-icon view-box="0 0 18 18"></share-icon>
                      공유
                    </button>
                  </li>
                  <li v-if="false">
                    <button class="sub-text-s2" @click="actions.copyClub()">
                      <copy-icon></copy-icon> 복제
                    </button>
                  </li>
                  <li>
                    <button
                      class="sub-text-s2 text-red-50 delete-btn"
                      @click="actions.openDeleteModal()"
                    >
                      <delete-icon fill-color="#FF4646"></delete-icon>삭제
                    </button>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </span>
        </div>
      </div>

      <div class="title-section">
        <h4
          @click="
            state.club.isTemporary
              ? actions.toggleGoToPCGuideModal()
              : actions.goToClubDetail()
          "
        >
          {{ club.title ? club.title : "제목 없음" }}
        </h4>
      </div>

      <ul class="club-info-wrapper">
        <li v-if="!club.isTemporary">
          <span class="text-gray-second b-text-3">매출</span>
          <span class="b-text-1">{{ state.salesPrice }}</span>
        </li>
        <li v-if="!club.isTemporary">
          <span class="text-gray-second b-text-3">상태</span>
          <span class="b-text-1">
            {{ state.statusLabelObj.label }}
            <span
              v-if="club.status === $const.clubStatus.reservation"
              class="b-text-1"
            >
              {{ state.statusLabelObj.startAt }}
            </span>
          </span>
        </li>
        <li>
          <span class="text-gray-second b-text-3">마지막 수정 날짜</span>
          <span class="b-text-1" v-html="state.updatedAt"></span>
        </li>
      </ul>

      <button-accordion
        v-if="!club.isTemporary"
        :reverse="true"
        @toggleContents="actions.toggleTicketInfo()"
      >
        <template #button>
          <button class="ticket-info-toggle-btn sub-title-s2">
            {{ state.showTicketInfo ? "접기" : "티켓정보" }}
            <arrow-icon
              fill-color="#00A3FF"
              :direction="state.showTicketInfo ? 'up' : 'down'"
            ></arrow-icon>
          </button>
        </template>

        <template #contents>
          <div class="ticket-info-card">
            <ul>
              <li
                v-for="plan in club.allPlans"
                :key="`ticket-${plan.resourceId}`"
              >
                <club-plan-item-mobile :plan="plan"></club-plan-item-mobile>
              </li>
            </ul>
          </div>
        </template>
      </button-accordion>

      <hr v-if="!club.isTemporary" class="hr-third" />

      <div class="btn-wrapper">
        <button @click="actions.toggleGoToPCGuideModal()">수정</button>
        <button v-if="!club.isTemporary" @click="actions.goToClubDetail()">
          상세 정보
        </button>
      </div>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showDeleteConfirmModal"
        warning-title="정말 삭제할까요?"
        warning-text="삭제된 유료 콘텐츠는 복구가 불가합니다."
        confirm-text="삭제"
        @hideModal="actions.closeDeleteConfirmModal()"
        @confirm="actions.deleteClub()"
      ></warning-modal>

      <warning-modal
        v-if="state.showDeleteWarningModal"
        warning-title="판매된 유료 콘텐츠는 삭제할 수 없어요"
        warning-text="삭제를 원하시면 고객센터로 문의 부탁드립니다."
        confirm-text="확인"
        :show-cancel-button="false"
        @hideModal="actions.closeDeleteWarningModal()"
        @confirm="actions.closeDeleteWarningModal()"
      ></warning-modal>

      <warning-modal
        v-if="state.showGoToPcModal"
        warning-title="PC에서 수정할 수 있어요"
        warning-text="수정을 원하신다면 PC 접속 부탁드려요."
        confirm-text="확인"
        :show-cancel-button="false"
        @confirm="actions.toggleGoToPCGuideModal()"
      ></warning-modal>
    </teleport>
  </div>
</template>

<script>
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown";
import { computed, getCurrentInstance, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import ClubPlanItem from "../ClubPlanItem/ClubPlanItem";
import { useStore } from "vuex";
import helper from "@/helper";
import Cookies from "js-cookie";
import swal from "@/helper/swal";
import WarningModal from "@/components/console/modals/WarningModal";
import ClubStatusDropdown from "../ClubStatusDropdown/ClubStatusDropdown";
import ExportIcon from "@/components/console/icons/ExportIcon";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon";
import CopyIcon from "@/components/console/icons/CopyIcon";
import DeleteIcon from "@/components/console/icons/DeleteIcon";
import ArrowIcon from "@/components/console/icons/ArrowIcon";
import ShareIcon from "@/components/console/icons/ShareIcon";
import { dateFormat } from "@/helper/date";
import ButtonAccordion from "@/components/console/buttons/ButtonAccordion";
import ClubPlanItemMobile from "../ClubPlanItemMobile/ClubPlanItemMobile";

export default {
  name: "ClubItem",
  components: {
    ClubPlanItemMobile,
    ButtonAccordion,
    ShareIcon,
    ArrowIcon,
    DeleteIcon,
    CopyIcon,
    MoreVertiIcon,
    ExportIcon,
    ClubStatusDropdown,
    WarningModal,
    ClubPlanItem,
    ButtonDropdown,
  },
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  emits: ["completeCopy"],
  setup(props, { emit }) {
    const ticketInfo = ref();
    const store = useStore();
    const { proxy } = getCurrentInstance();
    const toggleStatusDropdownBtn = ref();

    const router = useRouter();

    const state = reactive({
      club: computed(() => {
        return store.getters["newClubs/newClubs"].find((item) => {
          return item.resourceId === props.club.resourceId;
        });
      }),
      clubsMeta: computed(() => {
        return store.getters["newClubs/newClubsMeta"];
      }),
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      salesPrice: computed(() => {
        if (
          state.club.status === proxy.$const.clubStatus.open.value ||
          state.club.status === proxy.$const.clubStatus.finished.value
        ) {
          return "₩" + helper.priceFormat(state.club.salesPrice);
        } else {
          return "-";
        }
      }),
      updatedAt: computed(() => {
        return helper.dateTimeFormat(state.club.updatedAt);
      }),
      isReserveStatus: computed(() => {
        return (
          parseInt(state.club.status) ===
          proxy.$const.clubStatus.reservation.value
        );
      }),
      statusLabelObj: computed(() => {
        return {
          label: props.club.statusLabel,
          startAt: dateFormat(props.club.startAt),
        };
      }),
      isOpenStatus: computed(() => {
        return (
          parseInt(state.club.status) === proxy.$const.clubStatus.open.value
        );
      }),
      showTicketInfo: false,
      deletable: computed(() => {
        return state.club.salesCount === 0;
      }),
      startAt: computed(() => {
        return state.club.startAt
          ? helper.dateTimeFormat(state.club.startAt)
          : "-";
      }),
      showDeleteConfirmModal: false,
      showDeleteWarningModal: false,
      showGoToPcModal: false,
      errorMessage: "",
      statusDropdownAutoHide: true,
      showMobileDropdown: false,
    });

    const statusLabel = computed(() => {
      switch (state.club.status) {
        case 1:
          return "판매 예정";
        case 4:
          return "판매 종료";
        case 8:
          return "숨김";
        default:
          return "판매중";
      }
    });

    const actions = {
      toggleTicketInfo: () => {
        state.showTicketInfo = !state.showTicketInfo;
        if (!state.showTicketInfo) {
          ticketInfo.value.style.maxHeight = null;
        } else {
          ticketInfo.value.style.maxHeight = `${ticketInfo.value.scrollHeight}px`;
        }
      },
      goToClubDetail: () => {
        let routName = state.club.isTemporary ? "edit" : "show";
        router.push({
          name: `console.ent.clubs.${routName}`,
          params: {
            clubResourceId: state.club.resourceId,
          },
        });
      },
      goToClubPage: () => {
        let clubUrl = helper.getClubWebUrl(state.user, state.club);
        window.open(clubUrl, "_blank");
      },
      goToClubCms: () => {
        router.push({
          name: "console.ent.clubs.edit",
          params: {
            clubResourceId: state.club.resourceId,
          },
        });
      },
      openDeleteModal: () => {
        if (!state.deletable) {
          state.showDeleteWarningModal = true;
        } else {
          state.showDeleteConfirmModal = true;
        }
      },
      deleteClub: () => {
        if (state.deletable) {
          store
            .dispatch("newClubs/deleteClub", {
              clubResourceId: state.club.resourceId,
            })
            .then(() => {
              swal.successToast(`${state.club.title}이 삭제되었습니다.`);
            });
        } else {
          state.showDeleteWarningModal = true;
        }
      },
      closeDeleteWarningModal: () => {
        state.showDeleteWarningModal = false;
      },
      closeDeleteConfirmModal: () => {
        state.showDeleteConfirmModal = false;
      },
      copyClub: () => {
        store
          .dispatch("newClubs/copyClub", {
            clubResourceId: state.club.resourceId,
          })
          .then(() => {
            swal.successToast("복제 되었습니다.");
            emit("completeCopy");
          });
      },
      closeStatusDropdown: () => {
        toggleStatusDropdownBtn.value.click();
      },
      shareLink: async () => {
        state.showMobileDropdown = false;
        let sendUrl = await helper.clubShareUrl(state.user, state.club);

        helper.copyText(sendUrl);
        swal.createCompleteToast("주소가 복사되었습니다.");
      },
      setStatusDropdownAutoHide: (value) => {
        setTimeout(() => {
          state.statusDropdownAutoHide = value;
        }, 100);
      },
      toggleGoToPCGuideModal: () => {
        state.showGoToPcModal = !state.showGoToPcModal;
      },
      openMobileDropdown: () => {
        state.showMobileDropdown = true;
      },
    };

    return { state, actions, ticketInfo, toggleStatusDropdownBtn, statusLabel };
  },
};
</script>

<style src="./style.css" scoped></style>
