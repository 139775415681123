<template>
  <div class="page-container-new ent-meetings-page">
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading" class="ent-meetings">
      <page-header
        title="라이브 관리"
        :back-button="true"
        :route-name="{
          name: 'console.ent.meetings',
        }"
      ></page-header>

      <div class="club-detail-wrapper">
        <!--	라이브 탭 -->
        <div>
          <div class="btn-section">
            <button-basic
              class="create-btn"
              padding="14px 14px"
              text="라이브 추가"
              @action="actions.openSessionMeetingModal()"
            ></button-basic>
          </div>
          <div class="session-header sub-text-s3 text-gray-second">
            <span class="col1">라이브</span>
            <span class="col2">기능</span>
          </div>
          <template v-if="state.meetings.length > 0">
            <!--  클럽 세션  -->
            <div class="session-list-wrapper">
              <ul>
                <li
                  v-for="(meeting, index) in state.meetings"
                  :key="`meeting-${meeting.resourceId}`"
                >
                  <!-- todo ent 클럽 컴포넌트로 따로 빼기-->
                  <session-item
                    :index="index"
                    :meeting="meeting"
                    @openLiveFinishModal="actions.openLiveFinishModal()"
                    @openEditMeetingModal="
                      (meeting) => actions.openSessionMeetingModal(meeting)
                    "
                    @openDeleteMeetingModal="
                      (meeting) =>
                        actions.openSessionMeetingDeleteModal(meeting)
                    "
                  ></session-item>
                </li>
              </ul>
            </div>
          </template>

          <!--	session empty  -->
          <div v-if="state.meetings.length === 0" class="session-empty-card">
            <div class="directory-img">
              <img
                src="/assets/images/empties/empty_live.png"
                style="width: 100%"
              />
            </div>
            <p class="sub-text-s2 text-gray-second">
              생성된 라이브가 없습니다.<br />
              라이브 생성 버튼을 통해 라이브를 추가해보세요!
            </p>
            <button
              class="creat-btn"
              @click="actions.openSessionMeetingModal()"
            >
              <span class="sub-title-s2 text-default">라이브 추가하기</span>
              <arrow-icon class="arrow-icon"></arrow-icon>
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="state.meetingsMeta.pagination.lastPage > 1"
        class="pagination-wrapper"
      >
        <pagination
          :pagination="state.meetingsMeta.pagination"
          @getPageData="(page) => actions.getMeetingsMeta({ page: page })"
        ></pagination>
      </div>
    </div>

    <teleport :disabled="true">
      <warning-modal
        v-if="state.showLiveFinishWarningModal"
        :show-cancel-button="false"
        warning-title="종료된 라이브 입니다."
        confirm-text="확인"
        @confirm="actions.confirmLiveWarning()"
        @hideModal="actions.closeLiveFinishModal()"
      ></warning-modal>

      <warning-modal
        v-if="state.showSessionMeetingDeleteModal"
        warning-title="라이브를 삭제하시겠습니까?"
        warning-text="삭제 후 복구는 불가능합니다."
        confirm-text="삭제"
        @hideModal="actions.closeSessionMeetingDeleteModal()"
        @confirm="actions.deleteSessionMeeting()"
      ></warning-modal>

      <session-meeting-modal
        v-if="state.showSessionLiveModal"
        :meeting="state.editingMeeting"
        relation="meeting"
        @hideModal="actions.closeSessionLiveModal()"
        @saveComplete="actions.liveCreateComplete()"
        @updateMeetingStatus="actions.updateMeetingStatus()"
      ></session-meeting-modal>
    </teleport>
  </div>
</template>

<script>
import PageHeader from "../../../components/console/headers/PageHeader";
import PageLoading from "../../../components/console/loadings/PageLoading";
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import SessionItem from "../../../components/console/sessions/SessionItem/SessionItem";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import WarningModal from "../../../components/console/modals/WarningModal";
import ButtonBasic from "../../../components/console/buttons/ButtonBasic";
import SessionMeetingModal from "../../../components/console/sessions/SessionMeetingModal/SessionMeetingModal";
import swal from "@/helper/swal";
import ApiService from "@/api";
import Pagination from "@/components/console/pagination/Pagination.vue";

export default {
  name: "EntMeetings",
  components: {
    Pagination,
    WarningModal,
    ArrowIcon,
    SessionItem,
    PageLoading,
    PageHeader,
    ButtonBasic,
    SessionMeetingModal,
  },
  props: {},
  setup() {
    const store = useStore();

    const state = reactive({
      meetings: computed(() => {
        return store.getters["meetings/meetings"];
      }),
      meetingsMeta: computed(() => {
        return store.getters["meetings/meetingsMeta"];
      }),
      pageLoading: true,
      showLiveFinishWarningModal: false,
      showSessionLiveModal: false,
      //note 티켓관련
      editingMeeting: null,
      showSessionMeetingDeleteModal: false,
      deletingSessionMeeting: {},
      filters: {
        q: "",
        page: 1,
        status: 0,
        perPage: 15,
      },
    });

    onBeforeMount(async () => {
      await store.dispatch("meetings/getMeetings").then(() => {
        state.pageLoading = false;
      });
    });

    const actions = {
      getMeetingsMeta: (data) => {
        state.pageLoading = true;
        state.filters = { ...state.filters, ...data };
        store.dispatch("meetings/getMeetings", state.filters).then(() => {
          state.filters.page = state.meetingsMeta.pagination.currentPage;
          state.pageLoading = false;
        });
      },
      openLiveFinishModal: () => {
        state.showLiveFinishWarningModal = true;
      },
      closeLiveFinishModal: () => {
        state.showLiveFinishWarningModal = false;
      },
      openSessionMeetingModal: (session) => {
        if (!session) {
          state.editingMeeting = null;
        } else {
          state.editingMeeting = session;
        }
        state.showSessionLiveModal = true;
      },
      closeSessionLiveModal: () => {
        state.showSessionLiveModal = false;
      },
      liveCreateComplete: async () => {
        await store.dispatch("meetings/getMeetings").then(() => {
          if (state.editingMeeting) {
            swal.successToast(`LIVE가 수정되었습니다.`);
          } else {
            swal.successToast(`LIVE가 생성되었습니다.`);
          }
          state.showSessionLiveModal = false;
        });
      },
      openSessionMeetingDeleteModal: (meeting) => {
        state.showSessionMeetingDeleteModal = true;
        state.deletingSessionMeeting = meeting;
      },
      closeSessionMeetingDeleteModal: () => {
        state.deletingSessionMeeting = {};
        state.showSessionMeetingDeleteModal = false;
      },
      deleteSessionMeeting: async () => {
        try {
          let res = await ApiService.deleteMeetings(
            state.deletingSessionMeeting.resourceId
          );

          if (res.data.success) {
            state.deletingSessionMeeting = {};
            state.showSessionMeetingDeleteModal = false;
            await store.dispatch("meetings/getMeetings");
            swal.successToast("세션을 삭제했습니다.");
          } else {
            swal.messageErrorAlert(
              `티켓에 연결된 라이브는 삭제가 불가능합니다.`
            );
          }
        } catch (e) {
          swal.messageErrorAlert(e.message);
        }
      },
      updateMeetingStatus: () => {
        // todo 고도화 필요 - ui만 업데이트 시킴, 스토어에서 업데이트 시키도록 수정해야함. - todo-1
        swal.successToast(`LIVE 상태가 변경되었습니다.`);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
