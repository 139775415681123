
import { PropType } from "vue";

export default {
  name: "InputReadOnly",
  components: {},
  props: {
    label: {
      type: String as PropType<string>,
      default: "",
    },
    type: {
      type: String as PropType<string>,
      default: "text",
    },
    defaultValue: {
      type: String as PropType<string>,
      default: "",
    },
    placeholder: {
      type: String as PropType<string>,
      default: "",
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ["updateData"],
};
