<template>
  <!-- todo SelectableSessionItem 의 코드를 복사하여 사용, 리팩토링시 SelectableSessionItem과 함께 엮어서 사용 가능할지 고민. -->
  <div class="session-card">
    <div
      :class="{
        disable: state.content.isSelected,
        able: !state.content.isSelected,
      }"
    ></div>
    <div class="check-box-wrapper">
      <input-checkbox-new
        :default-value="state.selected"
        :disabled="state.content.isSelected"
        @updateData="(value) => actions.setSelected(value)"
      ></input-checkbox-new>
    </div>

    <div class="featured-img-wrapper">
      <div
        class="featured-img"
        :style="`background-image: url(${state.content.featuredImage})`"
      ></div>
    </div>
    <h6 class="title">
      {{ state.content.title }}
    </h6>
  </div>
</template>

<script>
import {
  computed,
  getCurrentInstance,
  onBeforeMount,
  onBeforeUpdate,
  reactive,
} from "vue";
import { useStore } from "vuex";
import InputCheckboxNew from "@/components/console/inputs/InputCheckboxNew.vue";

export default {
  name: "SessionContentsModalItem",
  components: { InputCheckboxNew },
  props: {
    content: {
      type: Object,
      required: true,
    },
    selectedContentResourceId: {
      type: String,
      default: "",
    },
  },
  emits: ["selectPoll"],
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      poll: {},
      sessions: computed(() => {
        return store.getters["newClubs/newClubSessions"];
      }),
      selected: false,
      isClub: computed(() => {
        let club = props.club;
        return !!club;
      }),
      selectedContentResourceId: computed(() => {
        let id = props.selectedContentResourceId;
        return id;
      }),
    });

    onBeforeMount(() => {
      state.content = props.content;
    });

    onBeforeUpdate(() => {
      console.log("onBeforUpdate");
      //todo computed에서 처리하는게 좋을지, onBeforeUpdate에서 처리하는게 좋을지 고민
      state.selected =
        state.selectedContentResourceId === state.content.resourceId;
    });

    const actions = {
      setSelected: (value) => {
        console.log(value);
        state.selected = value;
        console.log(state.selected);
        if (state.selected) {
          emit("selectContent", state.content.resourceId);
        } else {
          console.log("state.content.resourceId", state.content.resourceId);
          emit("selectContent", "");
        }
      },
    };

    return { state, actions, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
