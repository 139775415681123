<template>
  <div class="page-container-new">
    <page-loading v-if="state.pageLoading"></page-loading>

    <div v-if="!state.pageLoading">
      <page-header
        title="프리미엄 콘텐츠 관리"
        description="판매 중인 프리미엄 콘텐츠를 관리하고 구매한 멤버와 데이터 분석까지 확인해보세요."
        guide-link="https://bigc-im.notion.site/03a9db5551de463780d3a6917165eb48"
      ></page-header>

      <div class="container-body">
        <div class="tab-section">
          <button
            class="selling-tab sub-title-s1"
            :class="{ active: !state.isTemporary }"
            @click="actions.filterClubs({ isTemporary: 0 })"
          >
            {{ `판매 목록 ${state.clubsMeta.openClubCount}` }}
          </button>

          <text-divider
            color="#ECF1F4"
            height="20px"
            margin="0 20px"
          ></text-divider>

          <button
            class="selling-tab sub-title-s1"
            :class="{ active: state.isTemporary }"
            @click="actions.filterClubs({ isTemporary: 1 })"
          >
            {{ `임시 저장 ${state.clubsMeta.saveClubCount}` }}
          </button>
        </div>

        <div class="search-section">
          <h4>
            {{ `콘텐츠 ${state.clubsMeta.pagination.total}개` }}
          </h4>
          <div class="input-search__wrapper">
            <input-search
              v-model="state.filters.q"
              placeholder="검색어를 입력해주세요."
              shape="round"
              @search="getClubs({ q: state.filters.q })"
            ></input-search>
          </div>
        </div>

        <div
          class="contents-header sub-text-s3 text-gray-second"
          :class="{ temporary: state.isTemporary }"
        >
          <span class="col1">콘텐츠 종류</span>
          <span class="col2">기본정보</span>
          <span class="col3">매출</span>
          <span class="col4">
            <button-dropdown
              distance="8"
              :auto-hide="true"
              :disabled="state.isTemporary"
              placement="bottom-start"
            >
              <template #button>
                <div class="status-btn">
                  <span class="txt">상태</span>
                  <filter-icon
                    :fill-color="state.isTemporary ? 'transparent' : '#818287'"
                  ></filter-icon>
                </div>
              </template>
              <template #dropdownList>
                <ul class="status-list">
                  <li
                    v-for="(status, key) in state.clubsMeta.statuses"
                    :key="`club-status-${key}`"
                  >
                    <label>
                      <span>{{ status }}</span>
                      <input-checkbox
                        :default-value="state[`isClubStatus${key}`]"
                        @updateData="
                          (checked) =>
                            actions.filterClubs({
                              status: parseInt(key),
                              checked: checked,
                            })
                        "
                      ></input-checkbox>
                    </label>
                  </li>
                </ul>
              </template>
            </button-dropdown>
          </span>
          <span class="col5">마지막 수정 날짜</span>
          <span class="col6">기능</span>
        </div>

        <!--	empty UI  -->
        <empty-list
          v-if="state.clubs.length === 0"
          :icon-image="
            !state.clubsMeta.filters.q
              ? `/assets/images/club/document.png`
              : null
          "
          :text="
            !!state.clubsMeta.filters.q
              ? `'검색어'로 검색된 콘텐츠가 없습니다.`
              : `생성된 콘텐츠가 없습니다.<br/>프리미엄 콘텐츠를 만들어보세요!`
          "
          btn-text="프리미엄 콘텐츠 추가하기"
          :is-button="!state.clubsMeta.filters.q"
          @btnAction="actions.goToClubCreate()"
        >
          <template #buttonIcon>
            <arrow-icon class="arrow-icon" stroke-size="normal"></arrow-icon>
          </template>
        </empty-list>

        <ul class="club-list">
          <li v-for="club in state.clubs" :key="`club-item-${club.resourceId}`">
            <club-item
              :club="club"
              @completeCopy="actions.filterClubs({ isTemporary: 1 })"
            ></club-item>
          </li>
        </ul>

        <div
          v-if="state.clubsMeta.pagination.lastPage > 1"
          class="pagination-wrapper"
        >
          <pagination
            :pagination="state.clubsMeta.pagination"
            @getPageData="(page) => getClubs({ page: page })"
          ></pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import PageHeader from "../../../components/console/headers/PageHeader";
import InputSearch from "../../../components/console/inputs/InputSearch";
import Pagination from "../../../components/console/pagination/Pagination";
import ArrowIcon from "../../../components/console/icons/ArrowIcon";
import PageLoading from "../../../components/console/loadings/PageLoading";
import ClubItem from "./ClubItem/ClubItem";
import ButtonDropdown from "../../../components/console/buttons/ButtonDropdown";
import InputCheckbox from "../../../components/console/inputs/InputCheckbox";
import TextDivider from "../../../components/console/dividers/TextDivider";
import FilterIcon from "../../../components/console/icons/FilterIcon";
import EmptyList from "../../../components/console/empties/EmptyList";

export default {
  name: "Clubs",
  components: {
    EmptyList,
    FilterIcon,
    TextDivider,
    InputCheckbox,
    ButtonDropdown,
    ClubItem,
    PageLoading,
    ArrowIcon,
    Pagination,
    InputSearch,
    PageHeader,
  },
  props: {
    isTemporary: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const publicCheckValue = 1;
    const reservePublicCheckValue = 2;
    const notPublicCheckValue = 4;

    const state = reactive({
      filters: {
        q: "",
        status: 0,
        perPage: 15,
        isTemporary: props.isTemporary,
      },
      order: "desc", // default:desc(최신순), asc (오래된 순)
      clubs: computed(() => {
        return store.getters["newClubs/newClubs"];
      }),
      clubsMeta: computed(() => {
        return store.getters["newClubs/newClubsMeta"];
      }),
      pageLoading: false,
      // reservation 1
      isClubStatus1: computed(() => {
        // 1 3 5 7 9 11 13 15
        let status = Number(state.filters.status);
        return status & 1 ? true : false;
      }),
      // open 2
      isClubStatus2: computed(() => {
        // 2 3 6 7 10 11 14 15
        let status = Number(state.filters.status);
        return status & 2 ? true : false;
      }),
      // finished 4
      isClubStatus4: computed(() => {
        // 4 5 6 7 12 13 14 15
        let status = Number(state.filters.status);
        return status & 4 ? true : false;
      }),
      // hidden 8
      isClubStatus8: computed(() => {
        // 8 9 10 11 12 13 14 15
        let status = Number(state.filters.status);
        return status & 8 ? true : false;
      }),
      publicCheck: false,
      reservePublicCheck: false,
      notPublicCheck: false,
      getFiltering: false,
      currentTab: computed(() => {
        return state.clubsMeta.filters.isTemporary ? "temporary" : "selling";
      }),
      isTemporary: computed(() => {
        let isTemporary = state.clubsMeta.filters.isTemporary;
        return Number(isTemporary) === 1 ? true : false;
      }),
    });

    onBeforeMount(() => {
      getClubs();
    });

    const getClubs = (data) => {
      if (!state.getFiltering) {
        state.getFiltering = true;
        if (data) {
          state.filters = { ...state.filters, ...data };
          state.filters.isTemporary = Number(state.filters.isTemporary);
          store.dispatch("newClubs/getClubs", state.filters).then(() => {
            state.filters = { ...state.filters, ...state.clubsMeta.filters };
            state.getFiltering = false;
          });
        } else {
          state.pageLoading = true;
          state.filters.isTemporary = Number(state.filters.isTemporary);
          store.dispatch("newClubs/getClubs", state.filters).then(() => {
            state.filters = { ...state.filters, ...state.clubsMeta.filters };
            state.getFiltering = false;
            state.pageLoading = false;
          });
        }
      }
    };

    const actions = {
      togglePublicCheck: () => {
        state.publicCheck = !state.publicCheck;
        if (state.publicCheck) {
          state.filters.published += publicCheckValue;
        } else {
          state.filters.published -= publicCheckValue;
        }
        getClubs(state.filters);
      },
      toggleReservePublicCheck: () => {
        state.reservePublicCheck = !state.reservePublicCheck;
        if (state.reservePublicCheck) {
          state.filters.published += reservePublicCheckValue;
        } else {
          state.filters.published -= reservePublicCheckValue;
        }
        getClubs(state.filters);
      },
      toggleNotPublicCheck: () => {
        state.notPublicCheck = !state.notPublicCheck;
        if (state.notPublicCheck) {
          state.filters.published += notPublicCheckValue;
        } else {
          state.filters.published -= notPublicCheckValue;
        }
        getClubs(state.filters);
      },
      filterClubs: (data) => {
        const isTemporaryData = Object.keys(data).includes("isTemporary");
        const isStatusData = Object.keys(data).includes("status");
        const isTypeData = Object.keys(data).includes("type");

        // if (
        //   (!data.status &&
        //     data.isTemporary &&
        //     state.currentTab === "temporary") ||
        //   (!data.status && !data.isTemporary && state.currentTab === "selling")
        // ) {
        //   return;
        // }
        if (isStatusData) {
          if (data.checked) {
            state.filters.status = Number(state.filters.status) + data.status;
          } else {
            state.filters.status = Number(state.filters.status) - data.status;
          }
        }

        if (isTypeData) {
          state.filters.type = data.type;
        }

        if (isTemporaryData) {
          state.filters.isTemporary = data.isTemporary;
        }

        getClubs();
      },
      goToClubCreate: () => {
        router.push({ name: "console.themes" });
      },
    };

    return { state, actions, getClubs };
  },
};
</script>

<style scoped src="./style.css"></style>
