import Cookies from "js-cookie";
import helper from "@/helper";

const locale = Cookies.get("locale");
const currency = locale === "ko" ? "KRW" : "USD";
const currencyUnit = locale === "ko" ? "원" : "$";

export function planDiscount(plan) {
  if (plan.prices[currency] && plan.prices[currency].listPrice) {
    return (
      Math.round(
        ((plan.prices[currency].listPrice - plan.prices[currency].price) /
          plan.prices[currency].listPrice) *
          100
      ) + "%"
    );
  }
  return null;
}

export function planListPrice(plan, unitPosition = "back") {
  if (plan.prices[currency] && plan.prices[currency].listPrice) {
    if (unitPosition === "front") {
      let displayCurrencyUnit = currencyUnit === "원" ? "₩" : "$";
      return (
        displayCurrencyUnit +
        helper.priceFormat(plan.prices[currency].listPrice)
      );
    } else {
      return helper.priceFormat(plan.prices[currency].listPrice) + currencyUnit;
    }
  }
  return null;
}

export function planPrice(plan, unitPosition = "back") {
  if (plan.prices[currency]) {
    if (unitPosition === "front") {
      let displayCurrencyUnit = currencyUnit === "원" ? "₩" : "$";
      return (
        displayCurrencyUnit + helper.priceFormat(plan.prices[currency].price)
      );
    } else {
      return helper.priceFormat(plan.prices[currency].price) + currencyUnit;
    }
  }
  return null;
}

export function monthlyPrice(plan, month, unitPosition = "back") {
  if (plan.prices[currency]) {
    if (unitPosition === "front") {
      let displayCurrencyUnit = currencyUnit === "원" ? "₩" : "$";
      return (
        displayCurrencyUnit +
        helper.priceFormat(Math.floor(plan.prices[currency].price / month))
      );
    } else {
      return (
        helper.priceFormat(Math.floor(plan.prices[currency].price / month)) +
        currencyUnit
      );
    }
  }
  return null;
}

export function planSalesPrice(plan, unitPosition = "back") {
  if (unitPosition === "front") {
    let displayCurrencyUnit = currencyUnit === "원" ? "₩" : "$";
    return displayCurrencyUnit + helper.priceFormat(plan.salesPrice);
  } else {
    return helper.priceFormat(plan.salesPrice) + currencyUnit;
  }
}

export function planSoldOut(plan) {
  return plan.limitCount !== 0 && plan.limitCount === plan.paymentOrderCount;
}

export function isSoldPlan(plan) {
  return plan.paymentOrderCount > 0;
}
