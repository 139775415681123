<template>
  <div class="session-card">
    <div v-show="isSorting" class="event-barrier"></div>
    <div class="session-info">
      <button v-if="draggable" class="handle handler-btn">
        <handler-icon fill-color="#9EA0A6"></handler-icon>
      </button>
      <div class="header">
        <button v-if="draggable" class="handle handler-btn">
          <handler-icon fill-color="#9EA0A6"></handler-icon>
        </button>
        <badge
          :class="{ 'content-badge': !state.schedule }"
          :color="state.sessionType.color"
          :text="state.sessionType.text"
        ></badge>

        <span v-if="state.schedule" class="schedule c-text text-gray-third">
          {{ state.schedule }}
        </span>
        <!--        mo-->
        <div class="menu-dropdown-btn-wrapper">
          <button-dropdown distance="0">
            <template #button>
              <button class="menu-dropdown-btn">
                <more-verti-icon width="16" height="16"></more-verti-icon>
              </button>
            </template>
            <template #dropdownList>
              <ul class="menu-dropdown-list">
                <li v-if="!state.isMeeting">
                  <button
                    v-close-popper
                    class="item-dropdown-btn sub-text-s2"
                    @click="actions.openSessionMeetingEditModal()"
                  >
                    수정
                  </button>
                </li>
                <li>
                  <button
                    v-close-popper
                    class="item-dropdown-btn sub-text-s2 text-red-50"
                    @click="actions.openSessionMeetingDeleteModal()"
                  >
                    삭제
                  </button>
                </li>
              </ul>
            </template>
          </button-dropdown>
        </div>
      </div>

      <div class="featured-img-wrapper">
        <div
          ref="sessionFeaturedImage"
          class="featured-img"
          :style="`background-image: url(${state.backgroundImageSrc});`"
        ></div>
      </div>

      <div class="meta-info">
        <div class="header">
          <badge
            :color="state.sessionType.color"
            :text="state.sessionType.text"
          ></badge>

          <span v-if="state.schedule" class="schedule c-text text-gray-third">
            {{ state.schedule }}
          </span>
        </div>
        <p class="title text-default">{{ state.title }}</p>
        <p class="description text-gray-second" v-html="state.description"></p>
      </div>
    </div>
    <!--pc-->
    <div class="btn-wrapper" :class="{ meetings: !state.isClub }">
      <button-basic
        :text="state.buttonObj.text"
        :color="state.buttonObj.style.color"
        :bg-color="state.buttonObj.style.backgroundColor"
        padding="11px 16px"
        :disabled="state.buttonObj.disabled"
        @action="state.buttonObj.method()"
      ></button-basic>
      <button-basic
        v-if="state.isMeeting"
        text="수정"
        color="#0D0D10"
        bg-color="#ECF1F4"
        padding="11px 16px"
        @action="actions.goToMeetingSettingPage()"
      >
      </button-basic>
      <div class="menu-dropdown-btn-wrapper">
        <button-dropdown distance="0">
          <template #button>
            <button class="menu-dropdown-btn">
              <more-verti-icon width="16" height="16"></more-verti-icon>
            </button>
          </template>
          <template #dropdownList>
            <ul class="menu-dropdown-list">
              <li v-if="!state.isMeeting">
                <button
                  v-close-popper
                  class="item-dropdown-btn sub-text-s2"
                  @click="actions.openEditModal()"
                >
                  수정
                </button>
              </li>
              <li>
                <button
                  v-close-popper
                  class="item-dropdown-btn sub-text-s2 text-red-50"
                  @click="actions.openDeleteModal()"
                >
                  삭제
                </button>
              </li>
            </ul>
          </template>
        </button-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive, ref } from "vue";
import moment from "moment-timezone";
import SsoService from "../../../../services/SsoService";
import { useStore } from "vuex";
import Badge from "../../badges/Badge.vue";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";
import MoreVertiIcon from "@/components/console/icons/MoreVertiIcon.vue";
import ButtonDropdown from "@/components/console/buttons/ButtonDropdown.vue";
import { useRoute } from "vue-router";
import HandlerIcon from "@/components/console/icons/HandlerIcon.vue";

export default {
  name: "SessionItem",
  components: {
    HandlerIcon,
    ButtonDropdown,
    MoreVertiIcon,
    Badge,
    ButtonBasic,
  },
  props: {
    session: {
      type: Object,
      required: false,
    },
    club: {
      type: Object,
      required: false,
    },
    index: {
      type: Number,
      required: true,
    },
    meeting: {
      type: Object,
      required: false,
    },
    content: {
      type: Object,
      required: false,
    },
    poll: {
      type: Object,
      required: false,
    },
    draggable: {
      type: Boolean,
      default: false,
    },
    isSorting: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    "openLiveFinishModal",
    "openSessionMeetingEditModal",
    "openSessionMeetingDeleteModal",
    "openEditMeetingModal",
    "openDeleteMeetingModal",
    "openEditContentModal",
    "openDeleteContentModal",
    "openDeletePollModal",
    "editSessionPoll",
  ],
  setup(props, { emit }) {
    const sessionFeaturedImage = ref();
    const { proxy } = getCurrentInstance();
    const route = useRoute();

    const store = useStore();

    const state = reactive({
      userAlias: computed(() => {
        return store.getters["auth/aliasName"];
      }),
      isClub: computed(() => {
        let club = props.club;
        return !!club;
      }),
      sessionType: computed(() => {
        if (state.isClub) {
          if (props.session.meeting) {
            if (props.session.meeting.type === "streaming") {
              return proxy.$const.meetingTypes.streaming;
            } else if (props.session.meeting.type === "seminar") {
              return proxy.$const.meetingTypes.seminar;
            } else {
              return proxy.$const.meetingTypes.conference;
            }
          } else if (props.session.content) {
            return proxy.$const.sessionTypes.content;
          } else {
            return proxy.$const.sessionTypes.poll;
          }
        } else {
          if (props.meeting) {
            if (props.meeting.type === "streaming") {
              return proxy.$const.meetingTypes.streaming;
            } else if (props.meeting.type === "seminar") {
              return proxy.$const.meetingTypes.seminar;
            } else {
              return proxy.$const.meetingTypes.conference;
            }
          }
        }
        return proxy.$const.sessionTypes.content;
        // return proxy.$const.sessionTypes.content;
      }),
      isFinishLive: computed(() => {
        if (state.isClub && props.session?.meeting) {
          return (
            moment().diff(moment(props.session.meeting.scheduledFinishAt)) > 0
          );
        } else {
          return false;
        }
      }),
      buttonObj: computed(() => {
        let result = {
          text: "라이브 입장",
          style: {
            backgroundColor: "#8E1EFF",
            color: "#ffffff",
          },
          disabled: false,
          method: onBtnSSO,
        };

        if (state.isClub) {
          if (props.session?.content) {
            result.text = "보기";
            result.style.backgroundColor = "#ECF1F4";
            result.style.color = "#0D0D10";
            result.disabled = false;
            result.method = goToContent;
          } else if (props.session?.poll) {
            result.text = "보기";
            result.style.backgroundColor = "#ECF1F4";
            result.style.color = "#0D0D10";
            result.disabled = false;
            result.method = goToPoll;
          } else {
            if (state.isFinishLive) {
              result.text = "라이브 종료";
              result.disabled = true;
              result.style.backgroundColor = "#ECF1F4";
              result.style.color = "#D0D5DE";
              result.disabled = true;
              result.method = null;
            }
          }
        }

        return result;
      }),
      schedule: computed(() => {
        if (props.session?.meeting) {
          if (props.session.meeting.scheduledFinishAt) {
            return `${moment(props.session.meeting.scheduledAt).format(
              "YYYY.MM.DD hh:mm"
            )}~${moment(props.session.meeting.scheduledFinishAt)
              .tz(moment.tz.guess())
              .format("YYYY.MM.DD hh:mm z")}`;
          } else {
            return `${moment(props.session.meeting.scheduledAt).format(
              "YYYY.MM.DD hh:mm z [~]"
            )}`;
          }
        } else if (props.meeting) {
          if (props.meeting.scheduledFinishAt) {
            return `${moment(props.meeting.scheduledAt).format(
              "YYYY.MM.DD hh:mm"
            )}~${moment(props.meeting.scheduledFinishAt)
              .tz(moment.tz.guess())
              .format("YYYY.MM.DD hh:mm z")}`;
          } else {
            return `${moment(props.meeting.scheduledAt)
              .tz(moment.tz.guess())
              .format("YYYY.MM.DD hh:mm z [~]")}`;
          }
        } else if (props.session.poll) {
          return `${moment(props.session.poll.scheduledAt).format(
            "YYYY.MM.DD hh:mm"
          )}~${moment(props.session.poll.scheduledFinishAt)
            .tz(moment.tz.guess())
            .format("YYYY.MM.DD hh:mm z")}`;
        } else {
          return "";
        }
      }),
      title: computed(() => {
        if (props.session?.meeting) {
          return props.session.meeting.title;
        } else if (props.meeting) {
          return props.meeting.title;
        } else if (props.session.content) {
          return props.session.content.title;
        } else {
          return props.session.poll.title;
        }
      }),
      description: computed(() => {
        if (props.session?.meeting) {
          return props.session.meeting.description;
        } else if (props.meeting) {
          return props.meeting.description;
        } else if (props.session.content) {
          return props.session.content.description;
        } else {
          return props.session.poll.description;
        }
      }),
      backgroundImageSrc: computed(() => {
        if (props.session) {
          let session = props.session;
          if (session.meeting) {
            return session.meeting.featuredImage;
          } else if (session.content) {
            return session.content.featuredImage;
          } else {
            return session.poll.featuredImage;
          }
        } else {
          return props.meeting.featuredImage;
        }
      }),
      isMeeting: computed(() => {
        if (props.session?.meeting) {
          return true;
        }

        if (!props.session?.content && props.meeting) {
          return true;
        }
        return false;
      }),
    });

    const onBtnSSO = () => {
      if (props.session?.meeting || props.meeting) {
        const liveUrl = process.env.VUE_APP_LIVE_URL;

        let meetingResourceId = props.session?.meeting
          ? props.session.meeting.resourceId
          : props.meeting.resourceId;

        const redirect = `${liveUrl}/live/${meetingResourceId}`;
        const ssoEndPoint = `${liveUrl}/auth/sso`;

        SsoService.redirect(ssoEndPoint, redirect);
      }
    };

    const goToContent = () => {
      let routePath = `${process.env.VUE_APP_ENTER_URL}/${state.userAlias}/clubs/${props.club.resourceId}/sessions/${props.session.resourceId}`;
      window.open(routePath, "_blank");
    };

    const goToPoll = () => {
      emit("editSessionPoll", props.session);
    };

    const actions = {
      onBtnSSO: () => {
        onBtnSSO();
      },
      onBtnSessionView: () => {
        if (state.buttonObj.text === "라이브 입장") {
          if (
            props.session?.meeting &&
            moment().diff(moment(props.session.meeting.scheduledFinishAt)) > 0
          ) {
            emit("openLiveFinishModal");
            return;
          } else {
            onBtnSSO();
            return;
          }
        }
        if (state.buttonObj.text === "라이브 종료") {
          return;
        }

        if (state.buttonObj.text === "보기") {
          let routePath = `${process.env.VUE_APP_WEB_URL}/${state.userAlias}/clubs/${props.club.resourceId}/sessions/${props.session.resourceId}`;
          window.open(routePath, "_blank");
        }
      },
      openSessionMeetingEditModal: () => {
        if (props.session) {
          emit("openSessionMeetingEditModal", props.session);
        } else {
          emit("openSessionMeetingEditModal", props.meeting);
        }
      },
      openSessionMeetingDeleteModal: () => {
        if (props.session) {
          emit("openSessionMeetingDeleteModal", props.session);
        } else {
          emit("openSessionMeetingDeleteModal", props.meeting);
        }
      },
      openEditModal: () => {
        if (state.isClub) {
          if (props.session?.meeting) {
            emit("openEditMeetingModal", props.session);
          } else if (props.session?.poll) {
            emit("editSessionPoll", props.session);
          } else {
            emit("openEditContentModal", props.session);
          }
        } else {
          if (props.meeting) {
            emit("openEditMeetingModal", props.meeting);
          } else {
            emit("openEditContentModal", props.content);
          }
        }
      },
      openDeleteModal: () => {
        if (state.isClub) {
          if (props.session?.meeting) {
            emit("openDeleteMeetingModal", props.session);
          } else if (props.session?.poll) {
            emit("openDeletePollModal", props.session);
          } else {
            emit("openDeleteContentModal", props.session);
          }
        } else {
          if (props.meeting) {
            emit("openDeleteMeetingModal", props.meeting);
          } else {
            emit("openDeleteContentModal", props.content);
          }
        }
      },
      goToMeetingSettingPage: () => {
        let meetingResourceID = "";
        if (props.session?.meeting) {
          meetingResourceID = props.session.meeting.resourceId;
        }
        if (!props.session?.content && props.meeting) {
          meetingResourceID = props.meeting.resourceId;
        }
        let meetingPath = `${process.env.VUE_APP_LIVE_URL}/console/live/${meetingResourceID}/setting`;
        window.open(meetingPath, "_blank");
      },
    };

    return { state, actions, sessionFeaturedImage };
  },
};
</script>

<style src="./style.css" scoped></style>
