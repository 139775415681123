<template>
  <div class="status-card">
    <input-radio
      v-model="state.status"
      class="status-item"
      label="판매중"
      name="clubStatus"
      for-value="open"
      :value="$const.clubStatus.open.value"
    ></input-radio>
    <div class="status-item">
      <input-radio
        v-model="state.status"
        label="판매 예정"
        name="clubStatus"
        for-value="reservation"
        :value="$const.clubStatus.reservation.value"
      ></input-radio>
      <div
        v-if="parseInt(state.status) === $const.clubStatus.reservation.value"
        class="published-at"
      >
        <span>
          <input-date-picker
            placeholder="YYYY-MM-DD"
            :date="state.startAt"
            :enable-time="false"
            format="YYYY-MM-DD"
            :error="!!state.errorMessage"
            @openCalendar="actions.disableDropdownAutoHide()"
            @closeCalendar="actions.activeDropdownAutoHide()"
            @updateDate="
              (value) => {
                actions.setStartAt(value, 'date');
              }
            "
          ></input-date-picker>
        </span>
        <span>
          <input-date-picker
            placeholder="hh:mm"
            :date="state.startAt"
            :no-calendar="true"
            format="HH:mm"
            :error="!!state.errorMessage"
            @openCalendar="actions.disableDropdownAutoHide()"
            @closeCalendar="actions.activeDropdownAutoHide()"
            @updateDate="
              (value) => {
                actions.setStartAt(value, 'time');
              }
            "
          ></input-date-picker>
        </span>
      </div>
    </div>
    <input-radio
      v-model="state.status"
      class="status-item"
      label="판매 종료"
      name="clubStatus"
      for-value="finished"
      :value="$const.clubStatus.finished.value"
    ></input-radio>
    <input-radio
      v-model="state.status"
      class="status-item"
      label="숨김"
      name="clubStatus"
      for-value="hidden"
      :value="$const.clubStatus.hidden.value"
    ></input-radio>

    <div class="btn-wrapper">
      <button-basic
        text="취소"
        bg-color="#ECF1F4"
        color="#0d0d0d"
        @action="actions.closeDropdown()"
      ></button-basic>
      <button-basic
        :text="
          state.status === $const.clubStatus.reservation.value ? '예약' : '변경'
        "
        :disabled="state.disabledSaveButton"
        @action="actions.updateClubStatus()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, reactive } from "vue";
import InputRadio from "../../../../components/console/inputs/InputRadio";
import InputDatePicker from "../../../../components/console/inputs/InputDatePicker";
import { useStore } from "vuex";
import swal from "@/helper/swal";
import moment from "moment-timezone";
import ButtonBasic from "@/components/console/buttons/ButtonBasic.vue";

export default {
  name: "ClubStatusDropdown",
  components: { ButtonBasic, InputDatePicker, InputRadio },
  props: {
    club: {
      type: Object,
      required: true,
    },
  },
  emits: ["setClubStatus", "changeStatusDropdownAutoHide"],
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      status: props.club.status,
      startAt: props.club.startAt ? moment(props.club.startAt) : "",
      disabledSaveButton: computed(() => {
        return (
          parseInt(state.status) ===
            proxy.$const.clubStatus.reservation.value &&
          moment().diff(state.startAt) >= 0
        );
      }),
    });

    const actions = {
      updateClubStatus: () => {
        let payload = {
          status: parseInt(state.status),
        };

        if (
          (state.status === proxy.$const.clubStatus.reservation.value &&
            !state.startAt) ||
          (state.startAt && state.startAt < moment(new Date()).toJSON())
        ) {
          state.errorMessage = "현재 시간 이후로 설정해주세요.";
          return;
        }

        if (
          parseInt(state.status) === proxy.$const.clubStatus.reservation.value
        ) {
          payload.startAt = state.startAt.toJSON();
        }

        if (parseInt(state.status) === proxy.$const.clubStatus.open.value) {
          payload.startAt = moment().toJSON();
        }

        store
          .dispatch("newClubs/putClub", {
            clubResourceId: props.club.resourceId,
            data: payload,
          })
          .then(() => {
            emit("closeDropdown");
            swal.successToast("상태가 변경되었습니다.");
          });
      },
      closeDropdown: () => {
        emit("closeDropdown");
        state.status = props.club.status;
      },
      setStartAt: (date, type) => {
        let dateString = "";
        let timeString = "";
        let result = "";

        if (type === "date") {
          dateString = moment(date).format("YYYY-MM-DD");
          if (state.startAt) {
            timeString = moment(state.startAt).format("HH:mm");
          } else {
            timeString = moment().format("HH:mm");
          }
        }

        if (type === "time") {
          if (state.startAt) {
            dateString = moment(state.startAt).format("YYYY-MM-DD");
          } else {
            dateString = moment().format("YYYY-MM-DD");
          }
          timeString = moment(date).format("HH:mm");
        }

        result = `${dateString} ${timeString}`;

        state.startAt = moment(result);
      },
      disableDropdownAutoHide: () => {
        emit("changeStatusDropdownAutoHide", false);
      },
      activeDropdownAutoHide: () => {
        emit("changeStatusDropdownAutoHide", true);
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
