import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-380f4fe8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "input-basic" }
const _hoisted_2 = {
  key: 0,
  class: "sub-text-s2"
}
const _hoisted_3 = ["type", "value", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString($props.label), 1))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: "sub-text-s2",
      type: $props.type,
      value: $props.defaultValue,
      placeholder: $props.placeholder,
      disabled: $props.disabled
    }, null, 8, _hoisted_3)
  ]))
}