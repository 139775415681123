<template>
  <base-modal-side :is-dimmed="true" @closeModal="actions.closeModal()">
    <template #modalHeader>
      <div class="header">
        <h3>라이브 설정</h3>
        <p class="sub-text-s2 text-gray-second">
          라이브를 진행할 날짜와 시간을 선택해주세요!
        </p>
      </div>
    </template>

    <template #modalBody>
      <div v-if="relation === 'club-session' && !meeting">
        <button-basic
          class="add-btn"
          :text="state.isForm ? '목록 불러오기' : '라이브 생성'"
          color="#0d0d0d"
          bg-color="#ffffff"
          border-color="#ecebf1"
          icon-position="front"
          :border="true"
          @action="actions.switchToForm()"
        >
          <template v-if="!state.isForm" #icon>
            <plus-icon></plus-icon>
          </template>
        </button-basic>
      </div>
      <div v-if="state.isForm">
        <div v-if="meeting">
          <input-basic-new
            class="input-box"
            label="라이브 제목"
            :default-value="state.liveForm.title"
            @updateData="(value) => actions.updateMeetingTitle(value)"
          ></input-basic-new>
          <input-basic-new
            class="input-box"
            label="설명"
            :default-value="state.liveForm.description"
            @updateData="(value) => actions.updateMeetingDescription(value)"
          ></input-basic-new>

          <div>
            <label class="featured-img-label sub-text-s2">썸네일</label>
          </div>
          <div class="featured-img-wrapper">
            <div
              class="img-upload featured-file-overlay"
              @click="actions.openFileStack()"
            >
              <camera-button class="img-button"></camera-button>
            </div>
            <img
              class="featured-img"
              :src="state.liveForm.featuredImage"
              :alt="state.liveForm.featuredImage"
            />
          </div>

          <div class="publish-date time-area">
            <label>
              <span class="sub-text-s2">공개 일시</span>
              <span class="text-purple-50">*</span>
            </label>
            <div class="picker-wrapper">
              <input-date-picker
                :date="state.publishDate"
                format="YYYY-MM-DD HH:mm"
                @updateDate="(value) => actions.setPublishedDate(value)"
              ></input-date-picker>
            </div>
            <div
              v-show="state.isErrorPublishDate"
              class="sub-text-s3 text-red-50 error-message"
            >
              {{ state.isErrorPublishDate }}
            </div>
          </div>
        </div>

        <div class="live-start-date">
          <label>
            <span class="sub-text-s2">라이브 시작 일시</span>
            <span class="text-purple-50">*</span>
          </label>
          <input-date-picker
            :date="state.liveStartDate"
            format="YYYY-MM-DD HH:mm"
            @updateDate="(value) => actions.setLiveStartDate(value)"
          ></input-date-picker>
          <div
            v-show="state.isErrorLiveStartDate"
            class="sub-text-s3 text-red-50 error-message"
          >
            {{ state.isErrorLiveStartDate }}
          </div>
        </div>

        <div class="live-finish-date">
          <label>
            <span class="sub-text-s2">라이브 종료 일시</span>
            <span class="text-purple-50">*</span>
          </label>
          <div class="picker-wrapper">
            <input-date-picker
              :date="state.liveFinishDate"
              format="YYYY-MM-DD HH:mm"
              @updateDate="(value) => actions.setLiveFinishDate(value)"
            ></input-date-picker>
          </div>
          <div
            v-show="state.isErrorLiveFinishDate"
            class="sub-text-s3 text-red-50 error-message"
          >
            {{ state.isErrorLiveFinishDate }}
          </div>
        </div>

        <div v-if="meeting" class="state-area">
          <div>
            <label class="sub-text-s2">라이브 상태</label>

            <div class="live-status-area">
              <button-basic
                :class="{ inActive: state.isCreateMeeting }"
                :text="proxy.$const.meetingStatus.create.text"
                :bg-color="state.isCreateMeeting ? '#8E1EFF' : '#ECF1F4'"
                :color="state.isCreateMeeting ? '#FFFFFF' : '#0d0d0d'"
                :is-loading="state.isLoadingCreateMeeting"
                loading-icon-width="16"
                loading-icon-height="16"
                loading-iconview-box="0 0 24 24"
                @action="
                  state.isCreateMeeting
                    ? ''
                    : actions.openMeetingChangeAlertModal(
                        'create',
                        proxy.$const.meetingStatus.create.type
                      )
                "
              ></button-basic>
              <button-basic
                :class="{ inActive: state.isWaitingMeeting }"
                :text="proxy.$const.meetingStatus.waitting.text"
                :bg-color="state.isWaitingMeeting ? '#8E1EFF' : '#ECF1F4'"
                :color="state.isWaitingMeeting ? '#FFFFFF' : '#0d0d0d'"
                :is-loading="state.isLoadingWaitingMeeting"
                loading-icon-width="16"
                loading-icon-height="16"
                loading-iconview-box="0 0 24 24"
                @action="
                  state.isWaitingMeeting
                    ? ''
                    : actions.openMeetingChangeAlertModal(
                        'waitting',
                        proxy.$const.meetingStatus.waitting.type
                      )
                "
              ></button-basic>
              <button-basic
                :class="{ inActive: state.isStartedMeeting }"
                :text="proxy.$const.meetingStatus.started.text"
                :bg-color="state.isStartedMeeting ? '#8E1EFF' : '#ECF1F4'"
                :color="state.isStartedMeeting ? '#FFFFFF' : '#0d0d0d'"
                :is-loading="state.isLoadingStartedMeeting"
                loading-icon-width="16"
                loading-icon-height="16"
                loading-iconview-box="0 0 24 24"
                @action="
                  state.isStartedMeeting
                    ? ''
                    : actions.openMeetingChangeAlertModal(
                        'started',
                        proxy.$const.meetingStatus.started.type
                      )
                "
              ></button-basic>
              <button-basic
                :class="{ inActive: state.isFinishedMeeting }"
                :text="proxy.$const.meetingStatus.finished.text"
                :bg-color="state.isFinishedMeeting ? '#8E1EFF' : '#ECF1F4'"
                :color="state.isFinishedMeeting ? '#FFFFFF' : '#0d0d0d'"
                :is-loading="state.isLoadingFinishedMeeting"
                loading-icon-width="16"
                loading-icon-height="16"
                loading-iconview-box="0 0 24 24"
                @action="
                  state.isFinishedMeeting
                    ? ''
                    : actions.openMeetingChangeAlertModal(
                        'finished',
                        proxy.$const.meetingStatus.finished.type
                      )
                "
              ></button-basic>

              <template v-if="false">
                <input-radio
                  v-model="state.liveForm.status"
                  :label="proxy.$const.meetingStatus.create.text"
                  name="meetingStatus"
                  for-value="creatingMeeting"
                  :value="proxy.$const.meetingStatus.create.type"
                ></input-radio>
                <input-radio
                  v-model="state.liveForm.status"
                  :label="proxy.$const.meetingStatus.waitting.text"
                  name="meetingStatus"
                  for-value="waitingMeeting"
                  :value="proxy.$const.meetingStatus.waitting.type"
                ></input-radio>
                <input-radio
                  v-model="state.liveForm.status"
                  :label="proxy.$const.meetingStatus.started.text"
                  name="meetingStatus"
                  for-value="startedMeeting"
                  :value="proxy.$const.meetingStatus.started.type"
                ></input-radio>
                <input-radio
                  v-model="state.liveForm.status"
                  :label="proxy.$const.meetingStatus.finished.text"
                  name="meetingStatus"
                  for-value="finishedMeeting"
                  :value="proxy.$const.meetingStatus.finished.type"
                ></input-radio>
              </template>
            </div>
          </div>

          <div>
            <label class="sub-text-s2">라이브 타입</label>
            <div class="live-type-area live-type">
              <input-radio
                v-model="state.liveForm.type"
                label="스트리밍"
                name="meetingType"
                for-value="open-live-streaming"
                value="streaming"
              ></input-radio>
              <input-radio
                v-model="state.liveForm.type"
                label="세미나"
                name="meetingType"
                for-value="open-live-semina"
                value="seminar"
              ></input-radio>
              <input-radio
                v-model="state.liveForm.type"
                label="컨퍼런스"
                name="meetingType"
                for-value="open-live-conference"
                value="conference"
              ></input-radio>
            </div>
          </div>

          <div>
            <label class="sub-text-s2">Rtc Vendor</label>
            <div class="live-type-area live-type">
              <input-radio
                v-model="state.liveForm.rtcVendor"
                label="agora"
                name="rtcVendorType"
                for-value="open-agora"
                value="agora"
              ></input-radio>
              <input-radio
                v-model="state.liveForm.rtcVendor"
                label="ncloud"
                name="rtcVendorType"
                for-value="open-ncloud"
                value="ncloud"
              ></input-radio>
            </div>
          </div>
          <input-read-only
            class="input-box"
            label="Rtc channel Id"
            :disabled="true"
            :default-value="state.liveForm.rtcChannelId"
          >
          </input-read-only>
          <input-basic-new
            class="input-box"
            label="Streaming Playlist Url"
            :default-value="state.liveForm.streamingPlaylistUrl"
            @updateData="(value) => actions.updateMeetingSPListUrl(value)"
          ></input-basic-new>
          <div>
            <label class="sub-text-s2">state is recording</label>
            <div class="live-recording-area">
              <input-radio
                :model-value="state.liveForm.stateIsRecording"
                label="on"
                for-value="on"
                :value="true"
                name="IsRecording"
                :disabled="true"
              ></input-radio>
              <input-radio
                :model-value="state.liveForm.stateIsRecording"
                label="off"
                for-value="on"
                :value="false"
                name="IsRecording"
                :disabled="true"
              ></input-radio>
            </div>
          </div>

          <div>
            <input-read-only
              class="live-input"
              label="state recording sid"
              :disabled="state.readonly"
              :error-message="''"
              :default-value="state.liveForm.stateRecordingSid"
            ></input-read-only>
          </div>

          <div>
            <input-read-only
              class="live-input"
              label="state chat delay"
              :disabled="state.readonly"
              :default-value="state.liveForm.stateChatDelay"
            ></input-read-only>
          </div>

          <div>
            <input-read-only
              class="live-input"
              label="state pinned uid"
              :disabled="state.readonly"
              :default-value="state.liveForm.statePinnedUid"
            ></input-read-only>
          </div>
        </div>
      </div>
      <div v-if="relation === 'club-session' && !state.isForm">
        <ul>
          <li
            v-for="(item, index) in state.meetingList"
            :key="index"
            class="contents-item"
          >
            <session-contents-modal-item
              :content="item"
              :selected-content-resource-id="state.selectedContentResourceId"
              @select-content="(contentId) => actions.selectContent(contentId)"
            ></session-contents-modal-item>
          </li>
        </ul>
      </div>
    </template>
    <template #modalFooter>
      <div class="btn-wrapper">
        <button-basic
          class="cancel"
          text="취소"
          text-size="sub-title-s1"
          bg-color="#ECF1F4"
          color="#0d0d0d"
          @action="actions.closeModal()"
        ></button-basic>

        <button-basic
          class="save"
          :text="meeting ? '수정' : '등록'"
          text-size="sub-title-s1"
          :is-loading="state.isSaveLoading"
          :disabled="!state.activeSaveModal || state.isSaveLoading"
          @action="state.activeSaveModal ? actions.registerLiveSession() : ''"
        ></button-basic>
      </div>
    </template>
  </base-modal-side>
  <Teleport to="#teleport">
    <warning-modal
      v-if="state.showChangeMeetingAlertModal"
      warning-title="라이브 상태를 변경합니다."
      :warning-text="state.changeMeetingAlertMessage"
      confirm-text="확인"
      cancel-text="취소"
      @confirm="
        actions.changeMeetingStatus(
          'create',
          proxy.$const.meetingStatus.create.type
        )
      "
      @hideModal="actions.closeMeetingChangeAlertModal()"
    ></warning-modal>
  </Teleport>
</template>

<script>
import { reactive, computed, onBeforeMount, getCurrentInstance } from "vue";
import moment from "moment-timezone";
import { useStore } from "vuex";
import InputDatePicker from "../../inputs/InputDatePicker";
import BaseModalSide from "../../../common/BaseModalSide";
import ButtonBasic from "../../buttons/ButtonBasic";
import FileStackService from "@/services/FileStackService";
import InputRadio from "../../inputs/InputRadio";
import InputBasicNew from "../../inputs/InputBasicNew";
import InputReadOnly from "../../inputs/InputReadOnly";
import helper from "@/helper";
import CameraButton from "@/pages/console/EntClubShow/CameraButton/CameraButton.vue";
import WarningModal from "@/components/console/modals/WarningModal.vue";
import ApiService from "@/api";
import swal from "@/helper/swal";
import SessionContentsModalItem from "@/pages/console/EntClubShow/SessionContentsModalItem/SessionContentsModalItem.vue";
import PlusIcon from "@/components/console/icons/PlusIcon.vue";

export default {
  name: "SessionMeetingModal",
  components: {
    SessionContentsModalItem,
    CameraButton,
    InputReadOnly,
    ButtonBasic,
    BaseModalSide,
    InputDatePicker,
    InputRadio,
    InputBasicNew,
    WarningModal,
    PlusIcon,
  },
  props: {
    club: {
      type: Object,
      required: false,
    },
    meeting: {
      type: Object,
      required: false,
    },
    sessionId: {
      type: String,
      required: false,
      default: "",
    },
    relation: {
      validator(value) {
        // The value must match one of these strings
        return ["club-session", "meeting"].includes(value);
      },
    },
  },
  emits: ["hideModal", "saveComplete", "updateMeetingStatus"],
  setup(props, { emit }) {
    const store = useStore();
    const { proxy } = getCurrentInstance();

    const state = reactive({
      liveStartDate: "",
      liveFinishDate: "",
      activeSaveModal: computed(() => {
        if (state.isForm) {
          return (
            !state.isErrorPublishDate &&
            !state.isErrorLiveStartDate &&
            !state.isErrorLiveFinishDate
          );
        } else {
          return !!state.selectedContentResourceId;
        }
      }),
      isErrorPublishDate: computed(() => {
        if (
          props.meeting &&
          moment(state.publishDate).diff(state.liveStartDate) > 0
        ) {
          return "공개일은 시작일보다 빠를 수 없어요.";
        } else {
          return "";
        }
      }),
      isErrorLiveStartDate: computed(() => {
        if (moment(state.liveStartDate).diff(state.liveFinishDate) > 0) {
          return "시작일은 종료일보다 빠를 수 없어요.";
        } else if (
          !props.meeting &&
          moment(state.liveStartDate).diff(moment()) < 0
        ) {
          return "현재 시간 이후로 설정해주세요.";
        } else {
          return null;
        }
      }),
      isErrorLiveFinishDate: computed(() => {
        if (moment(state.liveFinishDate).diff(state.liveStartDate) < 0) {
          return "종료일은 시작일보다 빠를 수 없어요.";
        } else if (
          !props.meeting &&
          moment(state.liveFinishDate).diff(moment()) < 0
        ) {
          return "현재 시간 이후로 설정해주세요.";
        } else {
          return null;
        }
      }),
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      publishDate: "",
      liveForm: {
        status: 0,
        type: "",
        rtcVendor: "",
        rtcChannelId: "",
        streamingPlaylistUrl: "",
        isPublished: true,
        title: "",
        description: "",
        featuredImage: "https://cdn.bigc.im/statics/commons/images/empty.png",
        publishedAt: computed(() => {
          return moment(state.publishDate).toJSON();
        }),
        scheduledAt: computed(() => {
          return moment(state.liveStartDate).toJSON();
        }),
        scheduledFinishAt: computed(() => {
          return moment(state.liveFinishDate).toJSON();
        }),
        stateIsRecording: false,
        stateRecordingSid: "",
        stateChatDelay: 0,
        statePinnedUid: "",
      },
      errorMessage: "",
      upcomingMeetingResourceId: computed(() => {
        return store.getters["meetings/upcomingMeeting"].resourceId;
      }),
      readonly: true,
      isCreateMeeting: computed(() => {
        return state.liveForm.status === 0;
      }),
      isLoadingCreateMeeting: false,
      isWaitingMeeting: computed(() => {
        return state.liveForm.status === 1;
      }),
      isLoadingWaitingMeeting: false,
      isStartedMeeting: computed(() => {
        return state.liveForm.status === 3;
      }),
      isLoadingStartedMeeting: false,
      isFinishedMeeting: computed(() => {
        return state.liveForm.status === 5;
      }),
      isLoadingFinishedMeeting: false,
      isSaveLoading: false,
      showChangeMeetingAlertModal: false,
      meetingStatus: "",
      meetingType: "",
      changeMeetingAlertMessage: computed(() => {
        if (state.meetingType === 0) {
          return "라이브를 예정 상태로 변경합니다.";
        } else if (state.meetingType === 1) {
          return "라이브를 대기 상태로 변경합니다. 유저들이 대기실로 진입할 수 있어요.";
        } else if (state.meetingType === 3) {
          return "라이브를 시작합니다.";
        } else {
          return "라이브를 종료합니다.";
        }
      }),
      meetingList: [],
      isForm: false,
      selectedContentResourceId: "",
    });

    onBeforeMount(async () => {
      if (props.relation !== "club-session") {
        state.isForm = true;
      }

      if (props.meeting) {
        state.liveForm.status = props.meeting.status;
        state.liveForm.type = props.meeting.type;
        state.liveForm.rtcVendor = props.meeting.rtcVendor;
        state.liveForm.rtcChannelId = props.meeting.rtcChannelId;
        state.liveForm.streamingPlaylistUrl =
          props.meeting.streamingPlaylistUrl;
        state.liveForm.title = props.meeting.title;
        state.liveForm.description = props.meeting.description;
        state.liveStartDate = moment(props.meeting.scheduledAt);
        state.liveFinishDate = moment(props.meeting.scheduledFinishAt);
        state.liveForm.featuredImage = props.meeting.featuredImage;
        state.publishDate = moment(props.meeting.publishedAt);
        state.liveForm.stateIsRecording = props.meeting.stateIsRecording;
        state.liveForm.stateRecordingSid = props.meeting.stateRecordingSid;
        state.liveForm.stateChatDelay = props.meeting.stateChatDelay;
        state.liveForm.statePinnedUid = props.meeting.statePinnedUid;
      } else {
        state.liveForm.title = props.club
          ? props.club.title
          : `${helper.displayUserName(state.user)}의 라이브`;
        state.liveStartDate = moment().add(1, "hours");
        state.liveFinishDate = moment().add(2, "hours");

        if (props.relation === "club-session") {
          try {
            let paramsData = { clubResourceId: props.club.resourceId };
            const result = await ApiService.getClubLives(paramsData);
            const data = result.data.data;
            state.meetingList = data;
          } catch (e) {
            swal.messageErrorAlert(e.message);
          }
        }
      }
    });

    const actions = {
      closeModal: () => {
        emit("hideModal");
      },
      updateMeetingRtcChannelId: (value) => {
        state.liveForm.rtcChannelId = value;
      },
      // note streamingPlaylistUrl
      updateMeetingSPListUrl: (value) => {
        state.liveForm.streamingPlaylistUrl = value;
      },
      updateMeetingTitle: (value) => {
        state.liveForm.title = value;
      },
      updateMeetingDescription: (value) => {
        state.liveForm.description = value;
      },
      setLiveStartDate: (date) => {
        state.liveStartDate = moment(date);
      },
      setLiveFinishDate: (date) => {
        state.liveFinishDate = moment(date);
      },
      setPublishedDate: (date) => {
        state.publishDate = moment(date);
      },
      registerLiveSession: async () => {
        if (state.liveForm.scheduledAt < state.liveForm.scheduledFinishAt) {
          state.errorMessage = "시작시간이 종료시간보다 빠를 수 없습니다.";
        }

        let payload;
        state.isSaveLoading = true;
        if (props.meeting) {
          payload = {
            status: state.liveForm.status,
            type: state.liveForm.type,
            rtcVendor: state.liveForm.rtcVendor,
            rtcChannelId: state.liveForm.rtcChannelId,
            streamingPlaylistUrl: state.liveForm.streamingPlaylistUrl,
            isPublished: state.liveForm.isPublished,
            title: state.liveForm.title,
            description: state.liveForm.description,
            publishedAt: state.liveForm.publishedAt,
            scheduledAt: state.liveForm.scheduledAt,
            scheduledFinishAt: state.liveForm.scheduledFinishAt,
            stateIsRecording: state.liveForm.stateIsRecording,
            stateRecordingSid: state.liveForm.stateRecordingSid,
            stateChatDelay: state.liveForm.stateChatDelay,
            statePinnedUid: state.liveForm.statePinnedUid,
          };
          if (props.meeting.featuredImage !== state.liveForm.featuredImage) {
            payload.featuredImage = state.liveForm.featuredImage;
          }

          store
            .dispatch("meetings/putMeetings", {
              meetingResourceId: props.meeting.resourceId,
              data: payload,
            })
            .then(() => {
              emit("saveComplete");
            });
        } else {
          payload = {
            title: state.liveForm.title,
            scheduledAt: state.liveForm.scheduledAt,
            scheduledFinishAt: state.liveForm.scheduledFinishAt,
          };

          if (props.relation === "club-session") {
            if (state.isForm) {
              store
                .dispatch("newClubs/postClubSessionLive", {
                  clubResourceId: props.club.resourceId,
                  data: payload,
                })
                .then(() => {
                  emit("saveComplete");
                });
            } else {
              await store
                .dispatch("newClubs/postClubSessions", {
                  clubResourceId: props.club.resourceId,
                  data: {
                    model: "meeting",
                    resourceId: state.selectedContentResourceId,
                  },
                })
                .then(() => {
                  emit("saveComplete");
                });
            }
          } else {
            store.dispatch("meetings/postMeeting", payload).then(() => {
              emit("saveComplete");
            });
          }
        }
      },
      openFileStack: () => {
        let filestack = new FileStackService();
        filestack.options.transformations.crop.aspectRatio = 16 / 9;
        filestack.options.onFileUploadFinished = (fileMetaData) => {
          state.liveForm.featuredImage = fileMetaData.url;
        };

        filestack.open(filestack.options);
      },
      openMeetingChangeAlertModal: (status, type) => {
        state.meetingStatus = status;
        state.meetingType = type;
        state.showChangeMeetingAlertModal = true;
      },
      closeMeetingChangeAlertModal: () => {
        state.showChangeMeetingAlertModal = false;
      },
      changeMeetingStatus: () => {
        const status = state.meetingStatus;
        const type = state.meetingType;
        let payLoad = {
          meetingResourceId: props.meeting.resourceId,
        };
        let dispatchName;
        if (status === "create") {
          state.isLoadingCreateMeeting = true;
          dispatchName = "putMeetingsCreated";
        } else if (status === "waitting") {
          state.isLoadingWaitingMeeting = true;
          dispatchName = "putMeetingsWaiting";
        } else if (status === "started") {
          state.isLoadingStartedMeeting = true;
          dispatchName = "putMeetingsStart";
        } else {
          state.isLoadingFinishedMeeting = true;
          dispatchName = "putMeetingsFinish";
        }
        //todo 스토어 분리하기 - club에 연결된 미팅이 있고, club에 연결되지 않은 미팅이 있음, 두개를 분리해서 업데이트해야함 - todo-1
        store.dispatch(`meetings/${dispatchName}`, payLoad).then(() => {
          state.liveForm.status = type;
          if (props.relation === "club-session") {
            let session = store.getters["newClubs/newClub"].sessions.find(
              (item) => {
                return item.resourceId === props.sessionId;
              }
            );
            session.meeting.status = type;
          } else {
            let meeting = store.getters["meetings/meetings"].find((item) => {
              return item.resourceId === props.meeting.resourceId;
            });
            meeting.status = type;
          }
          state.showChangeMeetingAlertModal = false;
          emit("updateMeetingStatus");
          state.isLoadingCreateMeeting = false;
          state.isLoadingWaitingMeeting = false;
          state.isLoadingStartedMeeting = false;
          state.isLoadingFinishedMeeting = false;
        });
      },
      switchToForm: () => {
        !state.isForm && (state.selectedContentResourceId = "");
        state.isForm = !state.isForm;
      },
      selectContent: (contentResourceId) => {
        state.selectedContentResourceId = contentResourceId;
      },
    };

    return { state, actions, moment, proxy };
  },
};
</script>

<style src="./style.css" scoped></style>
